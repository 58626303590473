import React, { Component } from "react"
import Img from "gatsby-image"

class ImageSwitcher extends Component {
  constructor() {
    super();
    this.state = {
      hover: false
    }
  }

  mouseOver = (e) => {
	e.preventDefault();
    this.setState({ hover: true });
  }
  
  mouseOut = (e) => {
	e.preventDefault();
	this.setState({ hover: false });
  }

  touchStart = (e) => {
    this.setState({ hover: true });
  }
  
  touchEnd = (e) => {
	this.setState({ hover: false });
  }

  
  handleOnContextMenu = (e) => {
	  // e.preventDefault();
	  e.preventDefault();
	  
	  //this.setState({ hover: false });
	  return false;
  }

  render() {
	let {fluid0, fluid1, alt } = this.props;
    const { hover } = this.state;
	
	// <Img fluid={fluid0} alt={alt} style={{opacity: hover ? 0 : 1, borderRadius: "10px", transition: "opacity 1s ease-in-out"}} /> 
	// <Img fluid={fluid1} alt={alt} style={{opacity: hover ? 1 : 0, borderRadius: "10px", top: 0, position: "absolute"}} />
	
	// <Img fluid={fluid0} alt={alt} style={{display: hover ? "none" : "block", borderRadius: "10px", transition: "opacity 1s ease-in-out"}} /> 
	// <Img fluid={fluid1} alt={alt} style={{display: hover ? "block" : "none", borderRadius: "10px"}} />
	
	// Todo: , -webkit-animation: "fadeIn 1s"
	
	// onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} onTouchStart={this.mouseOver} onTouchEnd={this.mouseOut} onContextMenu={ this.handleOnContextMenu}
	// <div onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} onContextMenu={ this.handleOnContextMenu} onTouchStart={this.mouseOver} onTouchEnd={this.mouseOut} >
	// <div onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} onContextMenu={ this.handleOnContextMenu} onPointerDown={this.mouseOver} onPointerUp={this.mouseOut}  >
	
    return (
        <div onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} onContextMenu={ this.handleOnContextMenu} onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} >
			{ fluid1 === null ? 
				<Img fluid={fluid0} alt={alt} style={{borderRadius: "10px"}} /> :
				(
					<>
						<Img fluid={fluid0} alt={alt} style={{display: hover ? "none" : "block", borderRadius: "10px"}} /> 
						<Img fluid={fluid1} alt={alt} style={{display: hover ? "block" : "none", borderRadius: "10px", animation: "fadeIn 0.5s"}} />
					</>
				)
			}
			
			{ fluid1 && !hover ?
				<p style={{textAlign: "center", fontSize: "small", fontcolor: "rebeccapurple"}}>3D-Druck-Ergebnis anzeigen</p> :
				<p>&nbsp;</p>
			}
			
        </div>
    )
  }
}

export default ImageSwitcher


