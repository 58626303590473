import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tags from "../components/tags"
import ImageSwitcher from "../components/imageswitcher"
import GoogleAds from '../components/googleads'

const TagsTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} Beitr${
    totalCount === 1 ? "ag" : "äge"
  } mit Schlagwort "${tag}"`

  return (
    <Layout>
	  <SEO title={tagHeader} />
      <h1>{tagHeader}</h1>
	  <div style={{display: 'flex', flexWrap: 'wrap'}}>
		  {edges.map(({ node }) => (
				<div key={node.id} style={{ float: 'left', width: '300px', paddingRight: "3em", paddingTop: "2em" }}>
					<div style={{ minHeight: '70px'}}>
						<a href={node.frontmatter.path}>{node.frontmatter.title}</a><br/><span style={{fontSize: 'smaller'}}>{node.frontmatter.date}</span>
					</div>
					<div style={{ paddingTop: "1em" }}>
						<a href={node.frontmatter.path} style={{textDecoration: "none"}}>
							<ImageSwitcher fluid0={node.frontmatter.featuredImage.childImageSharp.fluid} fluid1={!node.frontmatter.featuredRWImage ? null : node.frontmatter.featuredRWImage.childImageSharp.fluid} />
						</a>
					</div>
				</div>
			  ))
		  }
	  </div>
	  <p style={{clear: "both"}}>&nbsp;</p>
      <Tags />
	  <GoogleAds client="ca-pub-5722800453178012" slot="6561537132" responsive="true" />
    </Layout>
  )
}

TagsTemplate.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
			  path: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default TagsTemplate

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
		   id
          frontmatter {
			date(formatString: "DD.MM.YYYY")
            title
			path
			featuredRWImage : featuredRWImageUrl {
			  childImageSharp {
				fluid(maxWidth: 800) {
				  ...GatsbyImageSharpFluid
				}
			  }
			}
			featuredImage : featuredImageUrl {
	          childImageSharp {
        	    fluid(maxWidth: 300) {
	              ...GatsbyImageSharpFluid
        	    }
	          }
            }
          }
        }
      }
    }
  }
`