import React from "react"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { useStaticQuery, Link, graphql } from "gatsby"

const Tags = () => {
	
	const data = useStaticQuery(graphql`
		query TagsComponentQuery {
		  allMarkdownRemark(limit: 2000) {
			  group(field: frontmatter___tags) {
				fieldValue
				totalCount
			  }	
			}
	    }
	`)
	
	return (
		<div>
		  <h2>Schlagworte</h2>
		  <div>
			{data.allMarkdownRemark.group.map(tag => (
			  <div key={tag.fieldValue} style={{float: "left", marginRight: "1em"}}>
				<Link to={`/de/tags/${kebabCase(tag.fieldValue)}`}>
				  {tag.fieldValue}
				</Link> ({tag.totalCount})
			  </div>
			))}
		  </div>
		  <div style={{clear: "both", marginBottom: "2em"}} />
		</div>
	)
}


export default Tags
