import React from "react"
import { Link } from "gatsby"

//  style={{textDecoration: "none"}}

const PostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.path}>
      {post.frontmatter.title}
    </Link><br/>
	<span style={{fontSize: 'smaller'}}>{post.frontmatter.date}</span>
  </div>
)

export default PostLink