import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import PostLink from "../components/post-link"
import Layout from "../components/layout"
import Tags from "../components/tags"
import SEO from "../components/seo"
import ImageSwitcher from "../components/imageswitcher"
import GoogleAds from '../components/googleads'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
   const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria 
    .map(edge => <div key={edge.node.id}  style={{ float: 'left', width: '300px', paddingRight: "3em", paddingTop: "2em" }}>  
		<div style={{ }}>
			<PostLink post={edge.node} />
		</div>
		<div style={{ paddingTop: "1em" }}>
			<a href={edge.node.frontmatter.path} style={{textDecoration: "none"}}>
				<ImageSwitcher fluid0={edge.node.frontmatter.featuredImage.childImageSharp.fluid} fluid1={!edge.node.frontmatter.featuredRWImage ? null : edge.node.frontmatter.featuredRWImage.childImageSharp.fluid} />
			</a>
		</div>
	</div>)
	
				// { edge.node.frontmatter.featuredRWImage && 
				//	 <Img fluid={edge.node.frontmatter.featuredRWImage.childImageSharp.fluid} />
				// }	
	
  return (<Layout>
    <SEO title="Home" />
    <h1>Willkommen!</h1>
    <p>3D-Design und -Druck kann Spaß machen und auch im privaten Umfeld Nützliches hervorbringen.</p>
    <p>Mein Name ist <a rel="noopener noreferrer" target="_blank" href="https://www.carecom.de">Harald Mühlhoff</a>. Und ich bin hier, um obige These zu belegen! ;)</p>
    <p>Für die exakte, millimetergenaue Konstruktion von Gegenständen, wie z.B. Ersatzteilen, habe ich persönlich mit <i>OpenSCAD</i> sehr gute Erfahrungen gemacht.
	Für den Anfang können Sie so ohne Kosten und Risiko Ihre Entwürfe erstellen und am Computer von allen Seiten bewundern. Der 3D-Druck erfolgt dann später
	über Dienstleister oder den eigenen <Link to="/de/blog/3dprinter">3D-Drucker</Link>. Ein <Link to="/de/blog/cura">Slicer</Link> (wie z.B. Ultimaker Cura) bereitet das 3D-Modell druckfertig auf.</p>
	<p>Aller Anfang ist einfach: <Link to="/de/blog/openscad">OpenSCAD Einführung und Installation</Link>.</p>
	<Tags />
    <h2>Beiträge</h2>
    <div style={{ display: 'flex', flexWrap: 'wrap'}}>{Posts}</div>
    <div style={{ clear: 'both', marginBottom: `1.45rem` }}>
      &nbsp;
    </div>
	<GoogleAds client="ca-pub-5722800453178012" slot="6561537132" fullWidthResponsive="true" />
  </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            path
            title
			featuredRWImage : featuredRWImageUrl {
			  childImageSharp {
        	    fluid(maxWidth: 400) {
	              ...GatsbyImageSharpFluid
        	    }
	          }
			}
	        featuredImage : featuredImageUrl  {
	          childImageSharp {
        	    fluid(maxWidth: 400) {
	              ...GatsbyImageSharpFluid
        	    }
	          }
            }
          }
        }
      }
    }
  }
`