import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tags from "../components/tags"
import ImageSwitcher from "../components/imageswitcher"
import GoogleAds from '../components/googleads'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
		<SEO title={frontmatter.title} />
		<div className="blog-post-container">
		  <div className="blog-post">
			<h1>{frontmatter.title}</h1>
			<h3>{frontmatter.date}</h3>
			<div style={{ maxWidth: `800px`, marginBottom: `1.45rem` }}>
				<ImageSwitcher fluid0={frontmatter.featuredImage.childImageSharp.fluid} fluid1={!frontmatter.featuredRWImage ? null : frontmatter.featuredRWImage.childImageSharp.fluid} />
			</div>
			<div
			  className="blog-post-content"
			  dangerouslySetInnerHTML={{ __html: html }}
			/>
		  </div>
		</div>
		<Tags />
		<GoogleAds client="ca-pub-5722800453178012" slot="6561537132" responsive="true" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        path
        title
		featuredRWImage : featuredRWImageUrl {
		  childImageSharp {
			fluid(maxWidth: 800) {
			  ...GatsbyImageSharpFluid
			}
		  }
		}
        featuredImage: featuredImageUrl  {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`