import React from 'react'
import Cookie from 'universal-cookie'

const cookie = new Cookie()

class CookieNotice extends React.Component {
  constructor(props) {
    super(props);
	this.state = { active: true };
  }

	componentDidMount = () => {
		this.setState({ active: cookie.get('viewed_cookie_notice') })
	}
 
render() {
    const { active } = this.state
	const date = new Date()
    return (
      <div id="cookie-notice" style={{ alignItems: "center", justifyContent: "center",
		  background: "linear-gradient(45deg, rgba(241,231,101,1) 0%, rgba(254,182,67,1) 100%)",
		  borderWidth: "1px", borderStyle: "solid", borderColor: "#303030",
			padding: "10px", position: "fixed", bottom: 10, margin: "0px auto", maxWidth: "960px", display: active ? "none" : "block" }}>
		Wir setzen Cookies ein, um die Website zu optimieren; Details siehe <a href="/de/impressum">Impressum / Datenschutzerklärung</a>.
			Mit der Nutzung stimmen Sie der Verwendung von Cookies 
			zu. <button type="button" id="close-cookie" style={{borderWidth: "1px", borderStyle: "solid", borderColor: "#303030", backgroundColor: "white"}}
			onClick={ () => { console.log("hello");
				cookie.set('viewed_cookie_notice', true, { path: '/', expires: new Date(date.getFullYear()+1, date.getMonth(), date.getDate()) })
				this.setState({ active: cookie.get('viewed_cookie_notice') })
			} }>
            Akzeptiert
          </button>
      </div>
    )
  }
}
export default CookieNotice
